
import { GenericPopUpClose, GenericPopUpInit } from "./genericPopup.js";

const init = () => {
    if ($('#inactivity-logout')) {
        GenericPopUpInit();
        $('#inactivity-logout').on('click', GenericPopUpClose);
    }
}

export default init;
